import React from "react";
import "./assets/css/DeleteAccountPage.css";
import { useHistory  } from "react-router-dom";



const DeleteAccountPage = () => {

    const history = useHistory();

    const handleConfirmation = () => {
        // Redirect to the delete-account page
        history.push("/delete-account");
    };


    return (
        <div className="delete-page-container">
            <div className="delete-content-box">
                <h2 className="delete-heading">Do you want to delete your account?</h2>
                <p className="delete-description">
                    We're sorry to see you go. Please note once your account is deleted:
                </p>
                <ul className="delete-info-list">
                    <li>We will no longer have any record of your orders or messages with our support team.</li>
                    <li>Your points and credits earned in this account will be forfeited.</li>
                    <li>You will no longer be able to participate in our exclusive events or rewards program.</li>
                    <li>You will no longer receive promotional emails.</li>
                </ul>
                <button className="delete-confirm-button" onClick={()=>handleConfirmation()}>Yes, I'm sure</button>
            </div>
        </div>
    );
};

export default DeleteAccountPage;
