import React from "react";
import "./assets/css/Confirmation.css";

const Confirmation = () => {
    return (
        <div className="confirmation-container">
            <div className="confirmation-box">
                <div className="checkmark">✔</div>
                <h2 style={{ color: '#888' }}>We have received your request.</h2>
                <p style={{ color: '#888' }}>Our team will send you a confirmation email shortly.</p>
                <p className="footer-text" style={{ color: '#888' }}>Made with Fillout, the easy way to make stunning forms</p>
            </div>
        </div>
    );
};

export default Confirmation;
