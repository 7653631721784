import React, { useState } from "react";
import "./assets/css/DeleteAccountPages.css";
import { setToast } from "../../util/toast";
import { useHistory  } from "react-router-dom";


const DeleteAccount = () => {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [userNameErrorMessage, setUserNameErrorMessage] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    
    

    const handleDeleteAccount = () => {
        let valid = validationDeleteAccount();
        if (valid) {
            setUserNameErrorMessage('')
            setPasswordErrorMessage("")
            setUsername('')
            setPassword('')
            setIsChecked(false)
            history.push("/delete-account-success");
        }
    };

    const validationDeleteAccount = () => {
        let valid = true;
        if (username.trim() == "") {
            valid = false;
            setUserNameErrorMessage('Please enter username')
        } else {
            var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
            if (!expr.test(username.trim())) {
                setUserNameErrorMessage('Enter Valid username')
                valid = false;
            } else {
                setUserNameErrorMessage('')
            }
        }

        if (password.trim() === "") {
            valid = false;
            setPasswordErrorMessage("Please enter password")
        } else if (password.length < 6) {
            valid = false;
            setPasswordErrorMessage("Password must be at least 6 characters.")
        } else {
            setPasswordErrorMessage("")
        }

        if (!isChecked) {
            valid = false;
            setToast("warning", "Please check terms and conditions.")
        }

        return valid;

    }

    return (
        <div className="delete-account-container">
            <div className="delete-account-box">
                <h2 className="delete-account-heading">Please enter your login details.</h2>
                <div className="input-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value)
                            setUserNameErrorMessage('')
                        }}
                        placeholder="Enter your username"
                    />
                    {<p className="error-message">{userNameErrorMessage}</p>}
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                            setPasswordErrorMessage("")
                        }}
                        placeholder="Enter your password"
                    />
                    {<p className="error-message">{passwordErrorMessage}</p>}
                </div>
                <div className="checkbox-group">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                    />
                    <label htmlFor="terms">
                        By deleting this account you agree to Dreamchat's Terms & Conditions. Once the account deletion process is initiated, it cannot be reversed, and you will no longer have access to your account or associated features.
                    </label>
                </div>
                {/* {errorMessage && <p className="error-message">{errorMessage}</p>} */}
                <button className="delete-button" onClick={handleDeleteAccount}>
                    Delete my account
                </button>
            </div>
        </div>
    );
};

export default DeleteAccount;
